* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: #3f3f3f;
}

h3 {
  margin: 1rem 0 0;
}

p {
  margin: 0;
  padding: 1rem 0;
}

a {
  text-decoration: none;
  color: #355E3B;
}

.h25 {
  font-size: 2rem;
  font-weight: 800;
}

.u-textCenter { text-align: center; }
.u-textUppercase { text-transform: uppercase; }
.u-padBottom2x { padding-bottom: 2rem; }

.slider {
  padding-top: 84px;
}

.stripe {
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-areas: '. content .';
  padding: 3rem 0;
}

.stripe--about h3 {
  margin-top: 0;
}

.stripe.stripe--location {
  display: inline;
  padding: 0;
  color: #FFF;
}

.stripe:nth-child(even) {
  background: #f0f0f0;
}

.content {
  grid-area: content;
}

.title { margin: 0; }
.title img { width: 112px; }

.anchorLink {
  margin-top: -115px;
}

.stripe--navigation {
  background: #FFFFFF;
  color: #000;
  min-height: auto;
  grid-template-columns: auto minmax(auto, 900px) auto;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  padding: .5rem 1rem;
  z-index: 99;
}

.stripe.stripe--main {
  min-height: 70vh;
  padding-top: 84px;
  padding-bottom: 0;
}

.slide {
  background-position: center;
  background-size: cover;
}

.slide1 { background-image: url('../img/shop.jpg'); }
.slide2 { background-image: url('../img/slide2.jpg'); }
.slide3 { background-image: url('../img/slide3.jpg'); }
.slide4 { background-image: url('../img/slide4.jpg'); }
.slide5 { background-image: url('../img/slide5.jpg'); }

.content--navigation {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.navigation ul {
  display: flex;
}

.navigation ul li {
  float: left;
  position: relative;
  list-style: none;
  padding: .4rem 1rem;
}

.staffer {
}

.staffer-thumbnail {
  width: 100%;
}

.staffer-thumbnail img {
  float: left;
  max-width: 100%;
  border-radius: .5rem;
}

.staffer-name {
  padding-bottom: 2rem;
}

.staffer:last-child {
  padding-bottom: 4rem;
}

.contact {
  padding: 1.5rem .5rem;
}

.bookNow {
  background: #000;
  color: #FFF;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: .5rem;
}

.content--map {
  grid-area: contentMap;
}
.content--location {
  grid-area: contentLocation;
  background: #355E3B;
  padding: 2rem;
}

.content--location a {
  color: #FFF;
  margin-right: 1rem;
}

.booksy-widget-container { display: none !important; }
.booksy-widget-container-default .booksy-widget-button {}

/* Nav */

.navButtonWrapper {
  align-self: center;
}

.navButton {
  float: right;
  position: relative;
  width: 20px;
  height: 14px;
}

.navButtonBar {
  float: left; width: 100%;
  position: absolute;
  height: 3px;
  background: #000;
  margin: 7px 0 0;
}

.navButtonBar--top { margin-top: 1px; }
.navButtonBar--bottom { margin-top: 13px; }

body.is-showingNav .navButtonBar--top {
  transform: rotate(45deg);
  margin-top: 7px;
}

body.is-showingNav .navButtonBar--middle {
  opacity: 0;
}

body.is-showingNav .navButtonBar--bottom {
  transform: rotate(-45deg);
  margin-top: 7px;
}

.nav-main {
  position: fixed;
  top: 5.25rem;
  left: 0;
  padding-top: 46px;
  background: #FFFFFF;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 99;
  overflow-y: scroll;
}

body.is-showingNav { overflow: hidden; }
body.is-showingNav .nav-main { display: block; }

.navMain-list {
  float: left; position: relative;
  width: 100%;
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 4rem 0 2rem;
}

.navMain-listItem {
  margin-bottom: .25rem;
  padding-bottom: 0;
}

a.navMain-listItem-link,
a.navMain-listItem-link:visited {
  color: #355E3B;
}
/* a.navMain-listItem-link:hover { color: blue; } */

.btn--subNav {
  box-shadow: 0 2px #355E3B;
}


.servicesList--service {
  float: left;
  width: 100%;
  display: block;
  border-bottom: 1px solid #9d9d9d;
  padding: .25rem 0;
  margin: 0 0 1rem;
}

.servicesList--item,
.servicesList--price,
.servicesList--description
 {
  float: left;
  width: 100%;
}

.servicesList--description {
  font-size: .8rem;
  padding: .5rem 0;
}

.servicesList--service.andUp .servicesList--price:after {
  content: '+';
  position: absolute;
}

.hours {
  display: grid;
  grid-template-columns: auto 1fr;
}

.copyright {
  float: right;
  position: absolute;
  padding: 0;
  margin: -2.5rem 0 0;
  right: 2rem;
}

.bookNow {
  border: none;
  background: #355E3B;
}

.bookNow--staffer {
  width: 100%;
  position: relative;
}

.staffer-thumbnail img,
.staffer-thumbnail button {
  float: left;
  cursor: pointer;
}

.staffer-thumbnail button {
  bottom: 1rem;
  transition: .2s ease-out;
}

.staffer-thumbnail button:hover {
  background: #000;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  margin: 0 0 3px .5rem;
/* transition: .25s ease-in-out; */
}

.servicesList--serviceOpen .arrow {
  margin-bottom: -1px;
  transform: rotate(-135deg);
}

.servicesList--withDescription {
  cursor: pointer;
}

/* end nav */

@media screen and (min-width: 600px) {
  .stafferContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }

  .stripe.stripe--navigation {
    padding: .5rem 2rem;
  }
}

@media screen and (min-width: 520px) {
  .servicesList--item { float: left; width: auto; }
  .servicesList--price { float: right; width: auto; }
}

@media screen and (min-width: 768px) {
  .stripe {
    grid-template-columns: auto 703px auto;
  }

  .stripe.stripe--navigation {
    grid-template-columns: auto minmax(auto, 900px) auto;
  }

  .stripe.stripe--main {
    min-height: auto;
    height: 600px;
  }

  .stripe.stripe--location {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'contentMap contentLocation';
    padding: 0;
    min-height: 50vh;
  }

  .staffer-thumbnail {
    height: 213px;
  }

  .staffer:last-child {
    padding-bottom: 0;
  }

  .servicesList--description { padding-bottom: 0; }
}
